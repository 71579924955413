import React, { useEffect, useState } from "react";
import { ChannelMemberResponse } from "stream-chat";
import {
  useChannelStateContext,
  Avatar,
  useChatContext,
} from "stream-chat-react";
import styles from "./HeaderSection.module.css";
import ConfirmIcon from "../../assets/ConfirmIcon";

import { Box, Menu } from "grommet";
import { Menu as HamburgerMenu, More, Edit, Add, Logout } from "grommet-icons";

import type { StreamChatGenerics } from "../../types";

type Props = {
  onAddUser: () => void;
  onCreateChannel: () => void;
  toggleMobile: () => void;
};

const getAvatarGroup = (
  members: ChannelMemberResponse<StreamChatGenerics>[]
) => {
  if (members.length === 1) {
    return (
      <div className={styles.avatars}>
        {members[0].user?.image ? (
          <Avatar image={members[0].user?.image} size={40} />
        ) : (
          <div className={`${styles.avatar}`}>
            {members[0].user?.name?.substring(0, 1)}
          </div>
        )}
      </div>
    );
  }

  if (members.length === 2) {
    return (
      <>
        <div className={`${styles.avatars} ${styles.two}`}>
          <span className={styles["avatar-half-center"]}>
            {members[0].user?.image ? (
              <Avatar image={members[0].user?.image} shape="square" size={40} />
            ) : (
              <div className={`${styles.avatar}`}>
                {members[0].user?.name?.substring(0, 1)}
              </div>
            )}
          </span>
          <span className={styles["avatar-half-center"]}>
            {members[1].user?.image ? (
              <Avatar image={members[1].user?.image} shape="square" size={40} />
            ) : (
              <div className={`${styles.avatar}`}>
                {members[1].user?.name?.substring(0, 1)}
              </div>
            )}
          </span>
        </div>
      </>
    );
  }

  if (members.length === 3) {
    return (
      <div className={`${styles.avatars} ${styles.three}`}>
        <span className={styles["avatar-half-center"]}>
          {members[0].user?.image ? (
            <Avatar image={members[0].user?.image} shape="square" size={40} />
          ) : (
            <div className={`${styles.avatar} ${styles.three} ${styles.first}`}>
              {members[0].user?.name?.substring(0, 1)}
            </div>
          )}
        </span>
        <span>
          {members[1].user?.image ? (
            <Avatar image={members[1].user?.image} shape="square" size={20} />
          ) : (
            <div className={`${styles.avatar} ${styles.three}`}>
              {members[1].user?.name?.substring(0, 1)}
            </div>
          )}
          {members[2].user?.image ? (
            <Avatar image={members[2].user?.image} shape="square" size={20} />
          ) : (
            <div className={`${styles.avatar} ${styles.three}`}>
              {members[2].user?.name?.substring(0, 1)}
            </div>
          )}
        </span>
      </div>
    );
  }

  if (members.length >= 4) {
    return (
      <div className={`${styles.avatars} ${styles.four}`}>
        <span>
          {members[members.length - 1].user?.image ? (
            <Avatar
              image={members[members.length - 1].user?.image}
              shape="square"
              size={20}
            />
          ) : (
            <div className={`${styles.avatar} ${styles.four}`}>
              {members[members.length - 1].user?.name?.substring(0, 1)}
            </div>
          )}

          {members[members.length - 2].user?.image ? (
            <Avatar
              image={members[members.length - 2].user?.image}
              shape="square"
              size={20}
            />
          ) : (
            <div className={`${styles.avatar} ${styles.four}`}>
              {members[members.length - 2].user?.name?.substring(0, 1)}
            </div>
          )}
        </span>
        <span>
          {members[members.length - 3].user?.image ? (
            <Avatar
              image={members[members.length - 3].user?.image}
              shape="square"
              size={20}
            />
          ) : (
            <div className={`${styles.avatar} ${styles.four}`}>
              {members[members.length - 3].user?.name?.substring(0, 1)}
            </div>
          )}

          {members[members.length - 4].user?.image ? (
            <Avatar
              image={members[members.length - 4].user?.image}
              shape="square"
              size={20}
            />
          ) : (
            <div className={`${styles.avatar} ${styles.four}`}>
              {members[members.length - 4].user?.name?.substring(0, 1)}
            </div>
          )}
        </span>
      </div>
    );
  }

  return null;
};

const HeaderSection: React.FC<Props> = (props) => {
  const { toggleMobile } = props;
  const { client, setActiveChannel } = useChatContext<StreamChatGenerics>();
  const { channel } = useChannelStateContext<StreamChatGenerics>();

  const [title, setTitle] = useState("");
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [channelName, setChannelName] = useState<string>(
    channel?.data?.name || ""
  );

  const [displayMembers, setDisplayMembers] = useState<boolean>(false);

  const members = Object.values(channel.state.members).filter(
    ({ user }) => user?.id !== client.userID
  );

  const inputRef = React.useRef<HTMLInputElement>(null);

  const channelUpdateHandler = async () => {
    if (channelName && channelName !== channel?.data?.name) {
      await channel.update(
        { name: channelName },
        { text: `Channel name changed to ${channelName}` }
      );
    }
    setIsEditing(false);
  };

  const leaveChatHandler = () => {
    setShowModal(false);
    if (members.length > 2) {
      channel.removeMembers([client.user!.id], {
        text: `${client.user?.name} left the chat`,
      });
    } else {
      channel.hide();
    }

    setActiveChannel(undefined);
  };

  const handleKeyDown = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    if (ev.key === "Enter") {
      ev.preventDefault();
      channelUpdateHandler();
    }
  };

  const displayMembersHandler = (ev: React.MouseEvent) => {
    setDisplayMembers(true);
  };

  useEffect(() => {
    if (!channelName) {
      setTitle(
        members
          .map(
            (member) => member.user?.name || member.user?.id || "Unnamed User"
          )
          .join(", ")
      );
    }
  }, [channelName, members]);

  return (
    <div className={styles["header-container"]}>
      <div className={styles["content-container"]}>
        <div className={styles.left}>
          <div
            id={styles["mobile-nav-icon"]}
            onClick={() => {
              setActiveChannel(undefined);
              toggleMobile();
            }}
          >
            <HamburgerMenu size="medium" />
          </div>
          <span
            onMouseEnter={(ev) => displayMembersHandler(ev)}
            onMouseLeave={() => setDisplayMembers(false)}
          >
            {getAvatarGroup(members)}
            {displayMembers ? (
              <div className={styles["members-list-container"]}>
                {members.map((member) => (
                  <p>{member.user?.name}</p>
                ))}
              </div>
            ) : (
              <></>
            )}
          </span>
          {!isEditing ? (
            <div className={styles["channel-name"]}>{channelName || title}</div>
          ) : (
            <input
              disabled={!isEditing}
              autoFocus
              className={styles["channel-name-input"]}
              onBlur={channelUpdateHandler}
              onChange={(e) => setChannelName(e.target.value)}
              placeholder="Channel Name..."
              ref={inputRef}
              value={channelName}
              onKeyPress={(ev) => handleKeyDown(ev)}
            />
          )}
        </div>
        {!isEditing && (
          <div className={styles.actions} title="Options">
            <Menu
              plain
              dropAlign={{ right: "right", top: "bottom" }}
              items={[
                {
                  label: (
                    <Box alignSelf="center">
                      {members.length < 2 ? "Create Chat" : "Invite User"}
                    </Box>
                  ),
                  onClick: () => {
                    members.length < 2
                      ? props.onCreateChannel()
                      : props.onAddUser();
                  },
                  icon: <Box pad="small">{<Add size="medium" />}</Box>,
                },
                {
                  label: <Box alignSelf="center">Edit Title</Box>,
                  onClick: () => {
                    setIsEditing(true);
                  },
                  icon: <Box pad="small">{<Edit size="medium" />}</Box>,
                },
                {
                  label: (
                    <Box alignSelf="center">
                      {members.length < 2 ? "Leave Chat" : "Leave Group"}
                    </Box>
                  ),
                  onClick: () => {
                    setShowModal(true);
                  },
                  icon: <Box pad="small">{<Logout size="medium" />}</Box>,
                },
              ]}
            >
              <Box direction="row" gap="small" pad="small">
                {<More />}
              </Box>
            </Menu>
          </div>
        )}
        {/* Onblur will trigger update, no need for event handlers on the icon */}
        {isEditing && <ConfirmIcon />}
      </div>

      <div
        onClick={() => setShowModal(false)}
        className={`${styles.overlay}  ${
          showModal === true ? styles.show : styles.hide
        }`}
      ></div>
      <div
        className={`${styles.modal} ${
          showModal === true ? styles.on : styles.off
        }`}
      >
        <div className={styles["modal-header"]}>
          <h2>Leave {members.length < 2 ? "Chat" : "Group"}</h2>
          <div></div>
        </div>
        <div className={styles["buttons-container"]}>
          <button
            className={`${styles.button} ${styles.cancel}`}
            onClick={() => setShowModal(false)}
          >
            Cancel
          </button>
          <button
            className={`${styles.button} ${styles.confirm}`}
            onClick={leaveChatHandler}
          >
            Leave Chat
          </button>
        </div>
      </div>
    </div>
  );
};

export default React.memo(HeaderSection);
