import React from "react";
import type {
  Channel,
  ChannelMemberResponse,
  MessageResponse,
} from "stream-chat";
import {
  Avatar,
  useChatContext,
  ChannelPreviewUIComponentProps,
} from "stream-chat-react";
import styles from "./CustomChannelPreview.module.css";
import type { StreamChatGenerics } from "../../types";

interface created_by {
  id: string;
  name: string;
}

const getAvatarGroup = (
  members: ChannelMemberResponse<StreamChatGenerics>[]
) => {
  if (members.length === 1) {
    return (
      <div className={styles.avatars}>
        {members[0].user?.image ? (
          <Avatar image={members[0].user?.image} size={40} />
        ) : (
          <div className={styles.avatar}>
            {members[0].user?.name?.substring(0, 1)}
          </div>
        )}
      </div>
    );
  }

  if (members.length === 2) {
    return (
      <div className={`${styles.avatars} ${styles.two}`}>
        <span className={styles["avatar-half-center"]}>
          {members[0].user?.image ? (
            <Avatar image={members[0].user?.image} size={40} shape="square" />
          ) : (
            <div className={styles.avatar}>
              {members[0].user?.name?.substring(0, 1)}
            </div>
          )}
        </span>
        <span className={styles["avatar-half-center"]}>
          {members[1].user?.image ? (
            <Avatar image={members[1].user?.image} size={40} shape="square" />
          ) : (
            <div className={styles.avatar}>
              {members[1].user?.name?.substring(0, 1)}
            </div>
          )}
        </span>
      </div>
    );
  }

  if (members.length === 3) {
    return (
      <div className={`${styles.avatars} ${styles.three}`}>
        <span className={styles["avatar-half-center"]}>
          {members[0].user?.image ? (
            <Avatar image={members[0].user?.image} shape="square" size={40} />
          ) : (
            <div className={`${styles.avatar} ${styles.three} ${styles.first}`}>
              {members[0].user?.name?.substring(0, 1)}
            </div>
          )}
        </span>
        <span>
          {members[1].user?.image ? (
            <Avatar image={members[1].user?.image} shape="square" size={20} />
          ) : (
            <div className={`${styles.avatar} ${styles.three}`}>
              {members[1].user?.name?.substring(0, 1)}
            </div>
          )}
          {members[2].user?.image ? (
            <Avatar image={members[2].user?.image} shape="square" size={20} />
          ) : (
            <div className={`${styles.avatar} ${styles.three}`}>
              {members[2].user?.name?.substring(0, 1)}
            </div>
          )}
        </span>
      </div>
    );
  }

  if (members.length >= 4) {
    return (
      <div className={`${styles.avatars} ${styles.four}`}>
        <span>
          {members[members.length - 1].user?.image ? (
            <Avatar
              image={members[members.length - 1].user?.image}
              shape="square"
              size={20}
            />
          ) : (
            <div className={`${styles.avatar} ${styles.four}`}>
              {members[members.length - 1].user?.name?.substring(0, 1)}
            </div>
          )}

          {members[members.length - 2].user?.image ? (
            <Avatar
              image={members[members.length - 2].user?.image}
              shape="square"
              size={20}
            />
          ) : (
            <div className={`${styles.avatar} ${styles.four}`}>
              {members[members.length - 2].user?.name?.substring(0, 1)}
            </div>
          )}
        </span>
        <span>
          {members[members.length - 3].user?.image ? (
            <Avatar
              image={members[members.length - 3].user?.image}
              shape="square"
              size={20}
            />
          ) : (
            <div className={`${styles.avatar} ${styles.four}`}>
              {members[members.length - 3].user?.name?.substring(0, 1)}
            </div>
          )}

          {members[members.length - 4].user?.image ? (
            <Avatar
              image={members[members.length - 4].user?.image}
              shape="square"
              size={20}
            />
          ) : (
            <div className={`${styles.avatar} ${styles.four}`}>
              {members[members.length - 4].user?.name?.substring(0, 1)}
            </div>
          )}
        </span>
      </div>
    );
  }

  return null;
};

const getTimeStamp = (channel: Channel) => {
  let lastHours = channel.state.last_message_at?.getHours();
  let lastMinutes = channel.state.last_message_at?.getMinutes();
  let half = "AM";

  if (lastHours === undefined || lastMinutes === undefined) {
    return "";
  }

  if (lastHours > 12) {
    lastHours = lastHours - 12;
    half = "PM";
  }

  if (lastHours === 0) lastHours = 12;
  if (lastHours === 12) half = "PM";

  if (lastMinutes.toString().length === 1) {
    return `${lastHours}:0${lastMinutes} ${half}`;
  }

  return `${lastHours}:${lastMinutes} ${half}`;
};

const getChannelName = (
  members: ChannelMemberResponse<StreamChatGenerics>[]
) => {
  const defaultName = "Untitled Group";
  if (!members.length || members.length === 1) {
    return members[0]?.user?.name || defaultName;
  }

  return `${members[0]?.user?.name || defaultName}, ${
    members[1]?.user?.name || defaultName
  }`;
};

type Props = ChannelPreviewUIComponentProps & {
  channel: Channel;
  onClose: () => void;
  reload: boolean;
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
};

const CustomChannelPreview: React.FC<Props> = (props) => {
  const {
    channel,
    latestMessage,
    setActiveChannel,
    onClose,
    reload,
    setReload,
    lastMessage,
  } = props;

  const { channel: activeChannel, client } =
    useChatContext<StreamChatGenerics>();

  const members = Object.values(channel.state.members).filter(
    ({ user }) => user?.id !== client.userID
  );

  let creator = channel?.data?.created_by as created_by;

  const pendingInvite = "invited" in channel.state.membership &&
    creator.id !== client.user?.id && (
      <div className={`${styles["invite-container"]}`}>
        <p>New message Request</p>
        <p>From {creator?.name}</p>
        <div className={styles["buttons-container"]}>
          <button
            className={`${styles.button} ${styles.confirm}`}
            onClick={async () => {
              await channel.rejectInvite({
                message: {
                  text: `${client.user?.name} rejected the invite.`,
                } as MessageResponse,
              });
              setReload(!reload);
            }}
          >
            Reject
          </button>
          <button
            className={`${styles.button} ${styles.cancel}`}
            onClick={async () => {
              await channel.acceptInvite({
                message: {
                  text: `${client.user?.name} accepted the invite.`,
                } as MessageResponse,
              });
              setReload(!reload);
            }}
          >
            Accept
          </button>
        </div>
      </div>
    );

  const acceptedInvite = (
    <div
      className={
        channel.id === activeChannel?.id
          ? `${styles["container-active"]} ${styles["container"]}`
          : `${styles["container-inactive"]} ${styles["container"]} ${
              channel.state.unreadCount > 0 && styles["has-unread"]
            }`
      }
      onClick={() => {
        onClose();
        setActiveChannel!(channel);
        const channelMembers = Object.values(channel.state.members);
        if (channelMembers.length === 2) {
          const channelClient = channelMembers.filter(
            (member) => member.user_id !== client.user?.id
          );

          const clientInfo = {
            tag: "clientInfo",
            unreadCount: channelClient[0].user_id,
          };
          window.parent.postMessage(
            clientInfo,
            "https://www.treehousecommunity.ca"
          );
        }
      }}
    >
      {getAvatarGroup(members)}
      <div className={styles.info}>
        <div className={styles.row}>
          <p className={styles.title}>
            {channel?.data?.name || getChannelName(members)}
          </p>
          <p className={styles.time}>{getTimeStamp(channel)}</p>
        </div>
        <div className={styles.row}>
          {latestMessage !== undefined ? (
            lastMessage?.text !== undefined && lastMessage?.text.length ? (
              lastMessage.text.length > 70 ? (
                lastMessage.text.substring(0, 70) + "..."
              ) : (
                lastMessage.text
              )
            ) : (
              latestMessage
            )
          ) : (
            <p className={styles.message}>Send a message</p>
          )}
          <span
            className={
              channel.id !== activeChannel?.id && channel.state.unreadCount > 0
                ? `${styles["circle"]} ${styles["show"]}`
                : `${styles["circle"]}`
            }
          ></span>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {!("invite_accepted_at" in channel.state.membership) &&
      creator.id !== client.user?.id
        ? pendingInvite
        : acceptedInvite}
    </>
  );
};

export default CustomChannelPreview;
