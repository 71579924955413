import React, { useCallback, useEffect, useState } from "react";
import { UserResponse } from "stream-chat";
import { Avatar, useChatContext } from "stream-chat-react";
import _debounce from "lodash.debounce";
import sha256 from "crypto-js/sha256";
import Hex from "crypto-js/enc-hex";
import { FormAdd, FormCheckmark, FormClose } from "grommet-icons";

import styles from "./CreateChannel.module.css";
import { Box } from "grommet/components/Box";

import type { StreamChatGenerics } from "../../types";

const UserResult = ({ user }: { user: UserResponse<StreamChatGenerics> }) => (
  <li className={styles["user-result"]}>
    {user.image ? (
      <Avatar image={user.image} size={40} />
    ) : (
      <div className={`${styles.avatar}`}>{user.name?.substring(0, 1)}</div>
    )}
    <div>
      <span>{user.name}</span>
    </div>
  </li>
);

const CreateChannel: React.FC<{
  isAdding: boolean;
  onClose: () => void;
  toggleMobile: () => void;
}> = ({ isAdding, onClose, toggleMobile }) => {
  const { client, setActiveChannel, channel } =
    useChatContext<StreamChatGenerics>();

  const [focusedUser, setFocusedUser] = useState<number | undefined>(undefined);
  const [userNameText, setUserNameText] = useState<string>("");
  const [resultsOpen, setResultsOpen] = useState<boolean>(false);
  const [searchEmpty, setSearchEmpty] = useState<boolean>(false);
  const [searching, setSearching] = useState<boolean>(false);
  const [selectedUsers, setSelectedUsers] = useState<
    UserResponse<StreamChatGenerics>[]
  >([]);
  const [users, setUsers] = useState<UserResponse<StreamChatGenerics>[]>([]);

  const inputRef = React.useRef<HTMLInputElement>(null);

  const clearState = () => {
    setUserNameText("");
    setResultsOpen(false);
    setSearchEmpty(false);
  };

  const findUsers = async () => {
    if (searching) return;
    setSearching(true);

    try {
      const response = await client.queryUsers(
        {
          id: { $ne: client.userID! },
          $and: [
            // {
            //   clinics: { $eq: client.user?.clinics },
            // },
            { name: { $autocomplete: userNameText } },
          ],
        },
        { id: 1 },
        { limit: 6 }
      );

      if (!response.users.length) {
        setSearchEmpty(true);
      } else {
        setSearchEmpty(false);
        setUsers(response.users);
      }

      setResultsOpen(true);
    } catch (error) {
      console.error({ error });
    }

    setSearching(false);
  };

  const findUsersDebounce = _debounce(findUsers, 100, {
    trailing: true,
  });

  const createChannel = async () => {
    const selectedUsersIds = selectedUsers.map((u) => u.id);

    if (!selectedUsersIds.length) return;

    const channelId = Hex.stringify(
      sha256([client.user!.id, ...selectedUsersIds].sort().join(":"))
    );

    const queryChannels = await client.queryChannels({
      id: { $eq: channelId },
    });

    if (queryChannels.length === 0) {
      const chat = client.channel("messaging", channelId, {
        members: [client.user!.id, ...selectedUsersIds],
        invites: selectedUsersIds,
        created_by_id: client.user!.id,
        created_by_name: client.user!.name,
      });

      await chat.watch();
      setActiveChannel(chat);
    } else {
      await queryChannels[0].show();
      setActiveChannel(queryChannels[0]);
    }

    setSelectedUsers([]);
    setUsers([]);
    onClose();
  };

  const invitehandler = async () => {
    const selectedUsersIds = selectedUsers.map((u) => u.id);

    if (!selectedUsersIds.length) return;

    await channel?.inviteMembers(selectedUsersIds);
    // await channel?.addMembers(selectedUsersIds);

    setSelectedUsers([]);
    setUsers([]);
    onClose();
  };

  const addUserHandler = (u: UserResponse<StreamChatGenerics>) => {
    const isAlreadyAdded = selectedUsers.find((user) => user.id === u.id);
    if (isAlreadyAdded) return;

    setSelectedUsers([...selectedUsers, u]);
    setResultsOpen(false);
    setUserNameText("");
    inputRef?.current?.focus();
  };

  const removeUser = (user: UserResponse<StreamChatGenerics>) => {
    const newUsers = selectedUsers.filter((item) => item.id !== user.id);
    setSelectedUsers(newUsers);
    inputRef?.current?.focus();
  };

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      // check for up(38) or down(40) key
      if (e.which === 38) {
        setFocusedUser((prevFocused) => {
          if (prevFocused === undefined) return 0;
          return prevFocused === 0 ? users.length - 1 : prevFocused - 1;
        });
      }
      if (e.which === 40) {
        setFocusedUser((prevFocused) => {
          if (prevFocused === undefined) return 0;
          return prevFocused === users.length - 1 ? 0 : prevFocused + 1;
        });
      }
      if (e.which === 13) {
        e.preventDefault();
        if (focusedUser !== undefined) {
          addUserHandler(users[focusedUser]);
          return setFocusedUser(undefined);
        }
      }
    },
    [users, focusedUser] // eslint-disable-line
  );

  useEffect(() => {
    const clickListener = () => {
      if (resultsOpen) clearState();
    };
    document.addEventListener("click", clickListener);
    return () => document.removeEventListener("click", clickListener);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (userNameText) {
      findUsersDebounce();
    }
  }, [userNameText]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown, false);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [handleKeyDown]);

  return (
    <div className={styles["header-container"]}>
      <header className={styles.header}>
        <div className={styles["content-container"]}>
          <div>To: </div>
          <div>
            {!!selectedUsers?.length && (
              <div className={styles.users}>
                {selectedUsers.map((user) => (
                  <div
                    className={styles.user}
                    onClick={() => removeUser(user)}
                    key={user.id}
                  >
                    <div>{user.name}</div>
                    <button className={styles["x-icon"]}>x</button>
                  </div>
                ))}
              </div>
            )}
            <form>
              <input
                autoFocus
                ref={inputRef}
                value={userNameText}
                onChange={(e) => setUserNameText(e.target.value)}
                placeholder={!selectedUsers.length ? "Search" : ""}
                type="text"
                className={styles["user-name-input"]}
              />
            </form>
          </div>
        </div>
        <div>
          <button
            className={styles["create-channel-button"]}
            onClick={isAdding ? invitehandler : createChannel}
          >
            {isAdding ? (
              <Box pad="small" title="Add">
                {<FormAdd size="medium" color="#0a3b59" />}
              </Box>
            ) : (
              <Box pad="small" title="Confirm">
                {<FormCheckmark size="medium" color="#0a3b59" />}
              </Box>
            )}
          </button>
          <button
            className={`${styles["create-channel-button"]} ${styles["desktop-cancel"]}`}
            onClick={onClose}
            title="Cancel"
          >
            {/* Cancel */}
            <Box pad="small">{<FormClose size="medium" color="#0a3b59" />}</Box>
          </button>

          <button
            className={`${styles["create-channel-button"]} ${styles["mobile-cancel"]}`}
            onClick={onClose}
          >
            {/* Cancel */}
            <Box pad="small">{<FormClose size="medium" color="#0a3b59" />}</Box>
          </button>
        </div>
      </header>
      {userNameText && (
        <main>
          <ul className={styles["user-list"]}>
            {!!users?.length && !searchEmpty && (
              <div>
                {users.map((user, i) => (
                  <div
                    className={`${styles["user-list-item"]} ${
                      focusedUser === i && styles.focused
                    }`}
                    onClick={() => addUserHandler(user)}
                    key={user.id}
                  >
                    <UserResult user={user} />
                  </div>
                ))}
              </div>
            )}
            {searchEmpty && (
              <div
                className={styles["no-results"]}
                onClick={() => {
                  inputRef?.current?.focus();
                  clearState();
                }}
              >
                No people found...
              </div>
            )}
          </ul>
        </main>
      )}
    </div>
  );
};

export default React.memo(CreateChannel);
