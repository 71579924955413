import React, { useEffect, useState } from "react";
import {
  Channel,
  ChannelList,
  Chat,
  LoadingIndicator,
  ChannelPreviewUIComponentProps,
} from "stream-chat-react";
import { GiphyContextProvider } from "./Giphy";
import { ChannelInner } from "./components/ChannelInner/ChannelInner";
import { ChannelFilters } from "stream-chat";
import { useMobileView } from "./hooks/useMobileView";
import { useConnectUser } from "./hooks/useConnectUser";
import CustomChannelList from "./components/CustomChannelList/CustomChannelList";
import "./App.css";

import { EmojiPicker } from 'stream-chat-react/emojis';

import sha256 from "crypto-js/sha256";
import Hex from "crypto-js/enc-hex";
import CustomChannelPreview from "./components/CustomChannelPreview/CustomChannelPreview";
import CreateChannel from "./components/CreateChannel/CreateChannel";
import EmptyList from "./components/EmptyList/EmptyList";
import type { StreamChatGenerics } from "./types";
import messageAlert from "./assets/messageAlert.mp3";

const App: React.FC<{
  userToken: string;
  userToConnect: { id: string; name?: string; image?: string };
  /**
   * Id of the user to open a chat with by default
   */
  defaultChatUserId: string | undefined;
}> = ({ userToken, userToConnect, defaultChatUserId }) => {
  const [isCreating, setIsCreating] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [reload, setReload] = useState<boolean>(false);

  const [activeChannelId, setActiveChannelId] = useState<string | undefined>(
    undefined
  );

  const chatClient = useConnectUser<StreamChatGenerics>(
    "73eb5ryc6ky7",
    userToConnect,
    userToken
  );

  const theme = "messaging light";
  const toggleMobile = useMobileView();

  const filters = {
    type: "messaging",

    $and: [
      { members: { $in: [userToConnect.id] } },
      {
        $or: [
          {
            joined: true,
          },
          {
            invite: "pending",
          },
        ],
      },
    ],
  } as ChannelFilters;

  const onCloseCreating = () => {
    setIsCreating(false);
    setIsAdding(false);
    toggleMobile();
  };

  // Play audio sound on new message event for all users except for the sender
  useEffect(() => {
    chatClient?.on("message.new", (event) => {
      if (userToConnect.id !== event.user?.id) {
        let audio = new Audio(messageAlert);
        audio.play();
      }

      if (event.unread_channels !== undefined) {
        const unreadCount = {
          tag: "unread Channels",
          unreadCount: event.unread_channels,
        };
        window.parent.postMessage(
          unreadCount,
          "https://www.treehousecommunity.ca"
        );
      }
    });
  }, [chatClient, userToConnect.id]);

  useEffect(() => {
    const createChannel = async () => {
      if (
        defaultChatUserId !== undefined &&
        chatClient !== null &&
        chatClient !== undefined
      ) {
        const channelId = Hex.stringify(
          sha256([userToConnect.id, defaultChatUserId].sort().join(":"))
        );

        const queryChannels = await chatClient.queryChannels({
          id: { $eq: channelId },
        });

        if (queryChannels.length === 0) {
          const chat = chatClient.channel("messaging", channelId, {
            members: [userToConnect.id, defaultChatUserId],
            invites: [defaultChatUserId],
            created_by_id: userToConnect.id,
            created_by_name: userToConnect.name,
          });
          await chat.watch();
          setActiveChannelId(channelId);
        } else {
          setActiveChannelId(channelId);
        }
      }
    };

    createChannel();
  }, [chatClient, defaultChatUserId, userToConnect.id, userToConnect.name]);

  if (!chatClient) {
    return (
      <div className="loading-indicator-container">
        <LoadingIndicator size={42} />
      </div>
    );
  }

  return (
    <Chat client={chatClient} theme={theme}>
      <div className="messaging__sidebar show" id="mobile-channel-list">
        <ChannelList
          key={"channelList" + reload}
          filters={filters}
          setActiveChannelOnMount={false}
          List={(props) => (
            <CustomChannelList
              {...props}
              onCreateChannel={() => setIsCreating((prev) => !prev)}
              defaultChatUserId={defaultChatUserId}
              activeChannelId={activeChannelId}
              setActiveChannelId={setActiveChannelId}
              toggleMobile={toggleMobile}
            />
          )}
          Preview={(props: ChannelPreviewUIComponentProps) => (
            <CustomChannelPreview
              {...props}
              {...{ reload, setReload }}
              onClose={onCloseCreating}
            />
          )}
          EmptyStateIndicator={EmptyList}
        />
      </div>

      <div className="container">
        {(isCreating || isAdding) && (
          <CreateChannel
            onClose={onCloseCreating}
            isAdding={isAdding}
            toggleMobile={toggleMobile}
          />
        )}
        {!isCreating && !isAdding && (
          <Channel EmojiPicker={EmojiPicker}>
            <GiphyContextProvider>
              <ChannelInner
                theme={theme}
                setIsAdding={setIsAdding}
                setIsCreating={setIsCreating}
                toggleMobile={toggleMobile}
              />
            </GiphyContextProvider>
          </Channel>
        )}
      </div>
    </Chat>
  );
};

export default App;
