import React, { useEffect, useRef } from "react";
import { logChatPromiseExecution, Event } from "stream-chat";
import {
  MessageList,
  MessageInput,
  Window,
  useChannelActionContext,
  Thread,
  useChatContext,
} from "stream-chat-react";

import { useGiphyContext } from "../../Giphy";

import HeaderSection from "../HeaderSection/HeaderSection";
import type { MessageToSend } from "stream-chat-react";
import type { StreamChatGenerics } from "../../types";

interface EmailNotificationPayload {
  sender: string;
  recipientIds?: string[];
}

export type ChannelInnerProps = {
  setIsAdding: React.Dispatch<React.SetStateAction<boolean>>;
  setIsCreating: React.Dispatch<React.SetStateAction<boolean>>;
  toggleMobile: () => void;
  theme: string;
};

export const ChannelInner = (props: ChannelInnerProps) => {
  const { setIsAdding, setIsCreating, toggleMobile } = props;
  const { giphyState, setGiphyState } = useGiphyContext();

  const { sendMessage } = useChannelActionContext<StreamChatGenerics>();

  const { channel, client } = useChatContext<StreamChatGenerics>();

  const timeRef = useRef(channel?.state.last_message_at);

  const overrideSubmitHandler = (
    message: MessageToSend<StreamChatGenerics>
  ) => {
    let updatedMessage;

    if (message.attachments?.length && message.text?.startsWith("/giphy")) {
      const updatedText = message.text.replace("/giphy", "");
      updatedMessage = { ...message, text: updatedText };
    }

    if (giphyState) {
      const updatedText = `/giphy ${message.text}`;
      updatedMessage = { ...message, text: updatedText };
    }

    if (sendMessage) {
      const newMessage = updatedMessage || message;
      const parentMessage = newMessage.parent;

      const messageToSend = {
        ...newMessage,
        parent: parentMessage
          ? {
              ...parentMessage,
              created_at: parentMessage.created_at?.toString(),
              pinned_at: parentMessage.pinned_at?.toString(),
              updated_at: parentMessage.updated_at?.toString(),
            }
          : undefined,
      };

      const sendMessagePromise = sendMessage(messageToSend);
      logChatPromiseExecution(sendMessagePromise, "send message");
    }

    setGiphyState(false);
  };

  /**
   * Handles checking the time elapased since the last message within the channel has been sent
   * If the specified threshold has been met then send an email notification to recipients who do not have the chat open/are not online
   * @param newMessageEvent new message event
   */
  const debounceMessage = (newMessageEvent: Event) => {
    if (timeRef.current !== undefined && timeRef.current !== null) {
      let difference = new Date().getTime() - timeRef.current.getTime();
      // 5 minutes
      if (difference > 300000) {
        if (channel !== undefined) {
          // Filter out the sender and map the ids of the recipients
          const recipients = Object.values(channel.state.members)
            .filter(({ user }) => user?.id !== newMessageEvent?.user?.id)
            .map((member) => member?.user?.id)
            .filter((memberId): memberId is string => memberId !== undefined);

          const watchers = Object.values(channel.state.watchers).map(
            (watcher) => watcher.id
          );
          // Filter out watchers
          const filteredRecipients = recipients.filter(
            (recipient) => !watchers.includes(recipient)
          );

          if (filteredRecipients.length > 0) {
            sendEmailRequest(newMessageEvent, filteredRecipients);
          }
        }
      }
    }

    timeRef.current = channel?.state.last_message_at;
  };

  /**
   * Handles the API request to send out the email notification for a new message
   * @param newMessageEvent new message event
   */
  const sendEmailRequest = (newMessageEvent: Event, recipients: string[]) => {
    const payload: EmailNotificationPayload = {
      sender: newMessageEvent?.user?.name ?? "",
      recipientIds: recipients ?? [],
    };

    const url = `https://community.treehousemedical.ca/_functions/sendChatEmail/`;
    const req = new Request(url, {
      headers: {
        "Content-Type": "application/json",
      },
      mode: "no-cors",
      method: "POST",
      body: JSON.stringify(payload),
    });

    fetch(req)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error(
          "Failed to send email notification " +
            response.status +
            " " +
            response.statusText
        );
      })
      .catch((err) => {
        console.error(err.code + ": " + err.message);
      });
  };

  useEffect(() => {
    const handleMessage = (msgEvent: Event) => {
      /**
       * Debounce if the event was triggered by the current user only
       * Play notification sound for everyone else
       */
      if (msgEvent.user?.id === client.user?.id) {
        debounceMessage(msgEvent);
      }
    };

    /**
     * New message event will be fired for all channel watchers, not only the sender
     * Channel watchers are all the users who are currently on the chat page
     */
    channel?.on("message.new", handleMessage);
    // Remove the event listener on unmount
    return () => channel?.off("message.new", handleMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel]);

  const actions = ["edit", "flag", "mute", "react", "reply"];

  return (
    <>
      <Window>
        <HeaderSection
          onAddUser={() => setIsAdding((prev) => !prev)}
          onCreateChannel={() => setIsCreating((prev) => !prev)}
          toggleMobile={toggleMobile}
        />
        <MessageList messageActions={actions} onlySenderCanEdit />
        <MessageInput focus overrideSubmitHandler={overrideSubmitHandler} audioRecordingEnabled asyncMessagesMultiSendEnabled />
      </Window>
      <Thread />
    </>
  );
};
