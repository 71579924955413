import * as React from "react";
import styles from "./Icons.module.css";

const AddChannelIcon: React.FC = () => {
  return (
    <svg width={32} height={32} viewBox="0 0 556 556" fill="none">
      <path
        className={styles.add}
        d="M69.5 404.49v70.427c0 6.486 5.097 11.583 11.583 11.583h70.427c3.012 0 6.023-1.158 8.108-3.475l252.98-252.748-86.875-86.875L72.975 396.15c-2.317 2.317-3.475 5.097-3.475 8.34zm410.282-241.397c9.035-9.035 9.035-23.63 0-32.665l-54.21-54.21c-9.035-9.035-23.63-9.035-32.665 0l-42.395 42.395 86.875 86.875 42.395-42.395z"
        fill="#0a3b59"
      />
    </svg>
  );
};

export default AddChannelIcon;
