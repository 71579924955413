import * as React from "react";
import styles from "./Icons.module.css";

const ConfirmIcon: React.FC = () => {
  return (
    <svg
      className={styles.confirm}
      width={46}
      height={46}
      viewBox="0 0 42 42"
      fill="none"
    >
      <path
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm4.59-12.42L10 14.17l-2.59-2.58L6 13l4 4 8-8-1.41-1.42z"
        fill="#0a3b59"
      />
    </svg>
  );
};

export default ConfirmIcon;
