import React from "react";
import styles from "./EmptyList.module.css";

const EmptyList: React.FC = () => {
  return (
    <div className={styles.container}>
      <h4>You have no active conversations</h4>
    </div>
  );
};

export default React.memo(EmptyList);
