import React, { PropsWithChildren, useEffect } from "react";
import {
  Avatar,
  ChannelListMessengerProps,
  useChatContext,
} from "stream-chat-react";
import AddChannelIcon from "../../assets/AddChannelIcon";
import styles from "./CustomChannelList.module.css";
import { SkeletonLoader } from "./SkeletonLoader";

import type { StreamChatGenerics } from "../../types";

interface ChannelListProps
  extends PropsWithChildren<ChannelListMessengerProps> {
  onCreateChannel: () => void;
  defaultChatUserId?: string;
  activeChannelId?: string;
  setActiveChannelId: React.Dispatch<React.SetStateAction<string | undefined>>;
  toggleMobile: () => void;
}

const CustomChannelList: React.FC<ChannelListProps> = (props) => {
  const { client, setActiveChannel } = useChatContext<StreamChatGenerics>();
  const {
    children,
    error = false,
    loading,
    onCreateChannel,
    toggleMobile,
  } = props;

  useEffect(() => {
    const setdefaultChannel = async () => {
      if (props.defaultChatUserId && props.activeChannelId) {
        const queryChannels = await client.queryChannels({
          id: { $eq: props.activeChannelId },
        });

        setActiveChannel(queryChannels[0]);
        props.setActiveChannelId(undefined);
      }
    };

    setdefaultChannel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error) {
    return (
      <div className={styles["list-container"]}>
        Error loading conversations, please try again momentarily.
      </div>
    );
  }

  if (loading) {
    return (
      <div
        className={`${styles["list-container"]} ${styles["show"]} ${styles["messaging__sidebar"]}`}
      >
        <SkeletonLoader />
      </div>
    );
  }

  return (
    <div className={styles["list-container"]}>
      <div className={styles["header-container"]}>
        <div className={styles["user-info"]}>
          <Avatar
            image={client.user?.image}
            name={client.user?.name}
            size={45}
          />
          <h4>{client.user?.name ?? ""}</h4>
        </div>
        <button
          className={`${styles["add-channel-icon"]}`}
          onClick={() => {
            onCreateChannel();
            toggleMobile();
          }}
          title="Create Chat"
        >
          <AddChannelIcon />
        </button>
      </div>
      {children}
    </div>
  );
};

export default CustomChannelList;
