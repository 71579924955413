import React from "react";
import ReactDOM from 'react-dom/client';

import "stream-chat-react/dist/css/index.css";
import App from "./App";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);


let authMessage:
  | {
      userId: string;
      userToken: string;
      name?: string;
      image: string | undefined;
      message: string | undefined;
    }
  | undefined;

window.addEventListener("message", (event) => {
  if (typeof event.data !== "string") return;
  authMessage = JSON.parse(event.data);

  // Fix issue with youtube embedded video sending its own window event and overwritting the existing one
  if (authMessage?.userToken === null || authMessage?.userToken === undefined) {
    return;
  }

  const userToConnect = {
    id: authMessage!.userId,
    name: authMessage?.name,
    image: authMessage?.image,
  };

  root.render(
    <React.StrictMode>
      {authMessage !== undefined ? (
        <App
          userToken={authMessage.userToken}
          userToConnect={userToConnect}
          defaultChatUserId={authMessage.message}
        />
      ) : (
        <h1>Loading...</h1>
      )}
    </React.StrictMode>,
  );
});
